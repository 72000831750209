<template>
    <div>
        <Header></Header>
        <Ranking ref="ranking" v-bind:hasFilterByGender="true"></Ranking>
    </div>
</template>

<script>
import Header from "../components/Header.vue";
import Ranking from "../components/Ranking.vue";

export default {
    name: "Edition2024",
    components: {
        Header,
        Ranking,
    },
    mounted() {
        this.$http.get("/api/ranking").then((response) => {
            this.$refs["ranking"].loadData(response.data.data);
            let vm = this;
            setTimeout(function () {
                vm.$root.$emit("ready");
            }, 50);
        });
    },
};
</script>
