<template>
    <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        v-on:click="$emit('click')"
    >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
    </a>
</template>
<script>
export default {
    name: "NavbarBurgerButton",

};
</script>
