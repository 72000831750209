<template>
  <div class="dropdown" v-bind:class="{ 'is-active': isOpen }">
    <div class="dropdown-trigger">
      <button
        class="button"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        v-on:click="isOpen = true"
      >
        <span>{{ selectedOption.value }}</span>
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a
          v-for="option in options"
          :key="option.key"
          class="dropdown-item"
          v-on:click="selectOption(option)"
        >
          {{ option.value }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      options: [
        {
          key: null,
          value: "Classificació general",
        },
        {
          key: "F",
          value: "Classificació femenina",
        },
      ],
      selectedOption: {
        key: null,
        value: "Classificació general",
      },
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
      this.isOpen = false;
      this.$emit("filter-selected", option.key);
    },
  },
  mounted() {
    this.$el.addEventListener("mouseleave", () => {
      this.isOpen = false;
    });
  },
};
</script>
