<template>
    <div
        id="pilot-details"
        class="modal"
        v-bind:class="{ 'is-active': isOpen }"
    >
        <div class="modal-background" v-on:click="close"></div>
        <div class="modal-content">
            <div class="card">
                <div class="card-content" v-if="data">
                    <div class="content">
                        <p class="title is-4">{{ data.name }}</p>
                    </div>
                    <div class="content has-text-left">
                        <div
                            class="my-5 mx-2 is-6"
                            v-for="(combo, index) in data.combos"
                            v-bind:key="combo.order"
                        >
                            <p class="title is-6">Combo #{{ index + 1 }}</p>
                            <ul class="combo-list">
                                <li
                                    v-for="(
                                        { from, to }, index
                                    ) in combo.combos"
                                    v-bind:key="index"
                                >
                                    {{ from }} ➜ {{ to }}
                                </li>
                                <li>{{ combo.score }}pts</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button
            class="modal-close is-large"
            aria-label="close"
            v-on:click="close"
        ></button>
    </div>
</template>

<script>
export default {
    name: "PilotDetails",
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        pilotId: {
            type: Number,
        },
    },
    data() {
        return {
            data: null,
            keyListener: null,
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        handleEscapeKey(key) {
            if (key.key === "Escape") this.$emit("close");
        }
    },
    watch: {
        pilotId: function (pilotId) {
            if (pilotId) {
                this.data = null;
                this.$http.get(`/api/pilot/${pilotId}`).then((response) => {
                    this.data = response.data.data;
                });
                if (!this.keyListener) {
                    window.addEventListener("keydown", this.handleEscapeKey);
                    this.keyListener = true;
                }
            } else {
                window.removeEventListener("keydown", this.handleEscapeKey);
                this.keyListener = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#pilot-details {
    right: 100vw;
    transition-property: right;
    transition-duration: 500ms;
    display: flex;

    &.is-active {
        right: 50vw;

        @media (max-width: 1000px) {
            right: 0;
        }

        .modal-close {
            display: flex;
        }

        .modal-content {
            display: block;
        }
    }

    .modal-close {
        display: none;
        position: absolute;
    }

    .modal-content {
        display: none;
        width: 80%;

        @media (max-width: 1000px) {
            width: 90%;
        }
    }

    .title.is-6 {
        margin-bottom: 0;
        &:not(:first-child) {
            margin-top: 1.5rem;
        }
    }
}
</style>
