<template>
  <tr v-bind:class="{ 'is-showing-all-hikes': showAllHikes }">
    <th class="w-75">
      <span>{{ position }}</span>
      <div v-if="showPositionVariations && value.position_variation" class="is-inline">
        <span v-if="value.position_variation < 0" class="has-text-danger">&#x25BC;</span>
        <span v-if="value.position_variation > 0" class="has-text-success">&#x25B2;</span>
        <span
          class="is-size-7 has-text-weight-normal"
          v-if="value.position_variation != 0"
        >
          <small>{{
            (value.position_variation > 0 ? "+" : "") + value.position_variation
          }}</small>
        </span>
      </div>
    </th>
    <td class="separator" v-bind:class="{ l75: showPositionVariations }">
      <span
        class="icon-text"
        v-bind:class="{ 'has-text-primary': showGenderIcon && value.gender == 'F' }"
      >
        <span>{{ value.complete_normal_name }}</span>
      </span>
    </td>
    <template v-for="zone in value.zones">
      <template v-for="peak in zone.peaks">
        <td class="px-1 is-hidden-mobile" v-bind:key="'peak_' + peak.id">
          <Ascencions :times="peak.times" :max-times="zone.max_times"></Ascencions>
        </td>
      </template>
      <td
        class="px-1 separator has-background-grey-lighter has-border-bottom-white is-hidden-mobile"
        v-bind:key="'zone_' + zone.id"
      >
        <Ascencions :times="zone.times" :max-times="zone.max_times"></Ascencions>
      </td>
    </template>
    <td class="px-1 separator">
      <span>{{ sumAscentsScore() }}</span>
    </td>
    <td class="px-1 separator">
      <span>{{ value.viviflies_score }}</span>
      <div>
        <template v-for="vivifly in viviflies">
          <span
            class="tag mx-1"
            v-bind:class="{ 'is-success': viviflyIsDone(vivifly) }"
            v-bind:key="vivifly.id"
          ></span>
        </template>
      </div>
    </td>
    <td
      class="px-1 separator"
      v-if="showCombosScore"
      v-bind:class="{ 'has-link': value.combo_score > 0 }"
      v-on:click="emitClickEvent"
    >
      <span>{{ value.combo_score }}</span>
      <Ascencions :times="value.combo_count" :max-times="3"></Ascencions>
    </td>
    <td class="px-1 separator">
      <div class="integral-score">
        <span
          class="tag mx-1"
          v-bind:class="{
            'is-success': value.is_semiintegral_completed,
            'is-warning':
              value.semiintegral_score > 0 && !value.is_semiintegral_completed,
          }"
          >Half
        </span>
        <small
          v-if="showIntegralTime && value.is_semiintegral_completed"
          class="is-size-7"
        >
          {{
            `${Math.floor(value.semiintegral_time / 60)}h ${String(
              value.semiintegral_time % 60
            ).padStart(2, "0")}m`
          }}
        </small>
        <small v-else-if="showIntegralTime && value.semiintegral_score" class="is-size-7">
          {{ value.semiintegral_score }}
        </small>
      </div>
      <div class="integral-score">
        <span
          class="tag mx-1"
          v-bind:class="{
            'is-success': value.is_integral_completed,
            'is-warning': value.integral_score > 0 && !value.is_integral_completed,
          }"
          >Full
        </span>
        <small v-if="showIntegralTime && value.is_integral_completed" class="is-size-7">
          {{
            `${Math.floor(value.integral_time / 60)}h ${String(
              value.integral_time % 60
            ).padStart(2, "0")}m`
          }}
        </small>
        <small v-else-if="showIntegralTime && value.integral_score" class="is-size-7">
          {{ value.integral_score }}
        </small>
      </div>
    </td>
    <td>{{ value.score }}</td>
  </tr>
</template>

<script>
import Ascencions from "./Ascencions.vue";

export default {
  name: "Pilot",
  components: {
    Ascencions,
  },
  props: {
    zones: Array,
    value: Object,
    viviflies: Array,
    position: Number,
    showAllHikes: Boolean,
    showIntegralTime: Boolean,
    showCombosScore: Boolean,
    showPositionVariations: Boolean,
    showGenderIcon: Boolean,
  },
  methods: {
    emitClickEvent() {
      if (this.value.combo_count) this.$emit("click", this.value.id);
    },
    viviflyIsDone(vivifly) {
      return this.value.viviflies.find((v) => v.pivot.vivifly_id == vivifly.id);
    },
    sumAscentsScore() {
      var score = (
          // total scoresum of ascents
        this.value.score
          // minus the integrals score
        - this.value.integral_score
        - this.value.semiintegral_score
          // minus the viviflies score
        - this.value.viviflies_score
          // minus the combo score
        - this.value.combo_score
      );

      this.value.hikes_score = score;

      return score;
    },
  },
};
</script>

<style lang="scss" scoped>
.is-showing-all-hikes {
  td.is-hidden-mobile {
    display: table-cell !important;
  }
}
.has-link {
  cursor: pointer;
}

.tag {
  display: inline;
}

.has-border-bottom-white {
  border-bottom-color: white !important;
}

.w-75 {
  min-width: 75px !important;
}

td,
th {
  vertical-align: middle !important;
}

.integral-score {
  white-space: nowrap;
  small {
    display: inline-block;
  }
}
</style>
