<template>
  <div>
    <span v-for="i in maxTimes" class="tag mx-1"
    v-bind:key="i"
    v-bind:class="{ 'is-success': i <= times }"></span>
  </div>
</template>

<script>
export default {
  name: "Ascencions",
  props: {
    times: Number,
    maxTimes: Number,
  },
};
</script>

<style scoped>
.tag {
  display: inline;
}
</style>
